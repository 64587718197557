import React from "react"

import Layout from "../components/layout.js"

// Components
import Footer from "../components/Footer.js"

const alkebulan = () => (
  <Layout>


    <Footer></Footer>
  </Layout>
)

export default alkebulan